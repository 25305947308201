<template>
    <div class="goods-shoot-content">
        <div>
            <div class="goods-shoot-header">
                <span style="margin-bottom: 28px;">素材下载</span>
            </div>
            <div class="task-table">
                <div class="table-title">
                    <span class="text">任务详情</span>
                    <span class="text">素材下载</span>
                    <span class="text" v-if="exam_module_name === '商品视频设计'">{{exam_module_name}}（支持拓展名：rar和zip，文件大小不能超过100M）</span>
                    <span class="text" v-else>{{exam_module_name}}（支持拓展名：jpg、jpeg和png，文件大小不能超过3M）</span>
                </div>
                <div class="table-body">
                    <span class="text">{{exam_module_name}}</span>
                    <span class="text download-materials" @click="downloadMaterials">点击下载</span>
                    <div class="upload text">
                        <el-button plain onclick="uploadFile.click()">选择文件</el-button>
                        <input class="file-upload-btn" ref="uploadFile" id="uploadFile" type="file"
                               :accept="exam_module_name === '商品视频设计' ? 'application/zip, application/rar' : 'image/jpg, image/jpeg, image/png'" title=""
                               @change="changeFile($event)">
                        <span class="upload-text">{{filesName}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div  style="text-align: center">
            <el-button type="primary" @click="saveVisualDesign" :disabled="submit_text === '上传中'">{{submit_text}}</el-button>
        </div>
    </div>
</template>

<script>
    import  Upload from '@/utils/slice_upload';
    import {collectUp_video, collectMerge, stuOpSaveVisualDesign, stuOpVisualDesignList, stuOpVisualDesignUploadImg} from '@/utils/apis'

    export default {
        name: "overall",
        data() {
            return {
                //考试模块名称
                exam_module_name: '',
                //文件名称
                filesName: '未选择任何文件',
                //图片上传
                cover_img: '',
                imageFile: '',
                //分片上传
                uploadInstance: null,
                material: '',
                //提交文字
                submit_text: '提交',
                //要下载的素材
                downloadMaterial: ''
            }
        },
        created() {
            this.getOverall();
        },
        methods: {
            //获取学生考试网店视觉设计内容
            getOverall() {
                let param = {}
                if (this.$route.query.id) {
                    param.op_id = this.$route.query.id;
                    param.course_id = this.$route.query.course_id;
                }
                stuOpVisualDesignList(param).then((res) => {
                    this.downloadMaterial = res.data.material_file;
                    this.exam_module_name = res.data.task_detail;
                    if (res.data.task_detail === '商品视频设计') {
                        this.material = res.data.file_path;
                    } else {
                        this.cover_img = res.data.file_path;
                    }
                    if (res.data.file_name) {
                        this.filesName = res.data.file_name;
                    } else {
                        this.filesName = '未选择任何文件';
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },
            //下载
            downloadMaterials() {
                window.location.href = this.downloadMaterial;
            },
            //选择文件
            changeFile(event) {
                if (event.target.files.length === 0) return;
                if (event.target.files[0].size > 1024 * 1024 * 100) {
                    this.$message.warning('文件超过100M');
                    return;
                } else {
                    let fileList = event.target.files[0];
                    this.imageFile = fileList;
                    this.filesName = fileList.name;
                    this.submit_text = '上传中';
                    let url = '';
                    let reader = new FileReader();
                    reader.readAsDataURL(fileList);
                    let that = this;
                    reader.onload = function (e) {
                        url = this.result.substring(this.result.indexOf(',') + 1);
                        that.cover_img = "data:image/png;base64," + url;
                    }
                    event.target.value = '';

                    if (this.exam_module_name === '商品视频设计') {
                        let chunkSize = 1024 * 1024 *10;
                        this.uploadInstance = new Upload(fileList, chunkSize, collectUp_video);
                        let index = 0;
                        this.uploadSlice(index);
                    } else {
                        this.uploadCover();
                    }
                }
            },
            //上传图片
            uploadCover() {
                let formData = new FormData();
                formData.append('img', this.imageFile);
                stuOpVisualDesignUploadImg(formData).then((res) => {
                    this.$message.success(res.msg);
                    this.submit_text = '提交';
                    this.cover_img = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //上传素材
            uploadSlice(index) {
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    collectMerge({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.submit_text = '提交';
                            this.material = res.data.src;
                            this.percent = 100;
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.material = res.data.src;
                            this.submit_text = '提交';
                            this.percent = 100;
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    }
                });
            },
            //提交
            saveVisualDesign() {
                let param = {
                    file_name: this.filesName
                }
                if (this.$route.query.id) {
                    param.course_id = Number(this.$route.query.course_id);
                    param.op_id = Number(this.$route.query.id);
                }
                if (this.exam_module_name === '商品视频设计') {
                    param.file_path = this.material;
                } else {
                    param.file_path = this.cover_img;
                }
                stuOpSaveVisualDesign(param).then((res) => {
                    this.$message.success(res.msg);
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-shoot-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        .goods-shoot-header {
            display: flex;
            justify-content: space-between;
            /*align-items: center;*/
            /*height: 58px;*/
            border-bottom: 2px solid #F1F5FF;
            span {
                color: #333;
                font-size: 16px;
            }
        }
    }
    .task-table {
        border: 1px solid #E5E5E5;
        margin-top: 16px;
        .table-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 60px;
            line-height: 60px;
            background: #F6F6F6;
            border-bottom: 1px solid #EEEEEE;
            box-sizing: border-box;
            padding: 0 40px;
        }
        .upload {
            display: flex !important;
            align-items: center;
        }
        .text {
            display: inline-block;
            &:nth-child(1) {
                width: 150px;
            }
            &:nth-child(3) {
                width: 550px;
            }
        }
        .table-body {
            display: flex;
            justify-content: space-between;
            height: 70px;
            line-height: 70px;
            padding: 0 40px;
        }
    }
    .file-upload-btn {
        display: none;
    }
    .upload-text {
        display: inline-block;
        flex: 1;
        width: 1%;
        color: #666;
        margin-left: 10px;
    }
    .is-plain {
        background: #FFF;
        border-color: #1CB064;
        color: #1CB064;
        border-radius: 2px;
    }
    .download-materials {
        color: #2461EF;
        cursor: pointer;
    }
</style>